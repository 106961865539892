<template>
  <VMenu
    v-model="menu"
    :close-on-content-click="closeOnContentClick"
    content-class="br-8"
    min-width="180"
    nudge-bottom="-5"
    nudge-left="120"
    offset-y
    open-on-hover
  >
    <template v-slot:activator="{ on, attrs }">
      <VHover v-slot="{ hover }">
        <VBtn
          v-bind="attrs"
          :class="hover ? btnHoverClass : ''"
          icon
          :style="`background-color: ${ btnColor }`"
          v-on="on"
          @mouseover="deleteConfirmation = false"
        >
          <VIcon v-text="btnIcon" />
        </VBtn>
      </VHover>
    </template>
    <VList
      class="pa-2"
      flat
    >
      <VHover v-slot="{ hover }">
        <VListItem
          :class="hover ? contentHoverClass : ''"
          @click="handleClick('edit')"
        >
          <VListItemTitle>
            Редактировать
          </VListItemTitle>
        </VListItem>
      </VHover>
      <VHover
        v-if="showDeactivate"
        v-slot="{ hover }"
      >
        <VListItem
          :class="hover ? contentHoverClass : ''"
          @click="handleClick('deactivate')"
        >
          <VListItemTitle>
            Деактивировать
          </VListItemTitle>
        </VListItem>
      </VHover>
      <span v-if="showDelete">
        <VHover
          v-if="!deleteConfirmation"
          v-slot="{ hover }"
        >
          <VListItem
            :class="hover ? contentHoverClass : ''"
            @click="showDeleteConfirmation"
          >
            <VListItemTitle>
              Удалить
            </VListItemTitle>
          </VListItem>
        </VHover>
        <VHover
          v-else
          v-slot="{ hover }"
        >
          <VListItem
            :class="hover ? contentHoverClass : ''"
            @click.stop="confirmDelete"
          >
            <VListItemTitle>
              Подтвердить удаление
            </VListItemTitle>
          </VListItem>
        </VHover>
      </span>
    </VList>
  </VMenu>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    deactivatable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    btnIcon: {
      type: String,
      default: 'mdi-dots-horizontal',
    },
    btnColor: {
      type: String,
      default: '',
    },
    btnHoverClass: {
      type: String,
      default: '',
    },
    contentHoverClass: {
      type: String,
      default: 'orange-text',
    },
  },
  emits: ['click'],
  data() {
    return {
      menu: false,
      closeOnContentClick: false,
      deleteConfirmation: false,
    };
  },
  computed: {
    showDeactivate() {
      const deactivatedStates = ['inactive', 'disabled', 'Деактивирован'];
      return this.deactivatable && !deactivatedStates.includes(this.item.state);
    },
    showDelete() {
      return this.deletable && this.item.state !== 'removed' && !this.item.isDeleted;
    },
  },
  methods: {
    showDeleteConfirmation() {
      this.closeOnContentClick = false;
      this.deleteConfirmation = true;
    },
    confirmDelete() {
      this.$emit('click', 'delete');
      this.deleteConfirmation = false;
      this.closeOnContentClick = true;
    },
    handleClick(action) {
      this.$emit('click', action);
      this.menu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .v-list-item {
    min-height: 35px;
    padding-left: 6px;
  }

  .orange-text {
    .v-list-item__title {
      color: #EE6002;
    }
  }

  .red-bg {
    border-radius: 4px;
    background-color: #FFEEEE;
  }
}

.grey-bg {
  background-color: #E5E5E5;
}
</style>
